var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: "添加部门成员", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _vm.queryModel
        ? _c(
            "div",
            { staticClass: "org-user-add-component form-edit-component" },
            [
              _vm.isProjectAdd
                ? _c(
                    "div",
                    { staticClass: "org-cont" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                        _vm._v("组织架构:"),
                      ]),
                      _c("jtl-tree-select-component", {
                        staticClass: "width100",
                        attrs: {
                          multiple: false,
                          "tree-data": _vm.orgProjectTreeData,
                          placeholder: "请选择成员将要添加到的项目组织架构",
                        },
                        model: {
                          value: _vm.queryModel.excludeOrgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryModel, "excludeOrgId", $$v)
                          },
                          expression: "queryModel.excludeOrgId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "org-cont" },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("快速选择企业成员:"),
                  ]),
                  _c("jtl-tree-select-component", {
                    staticClass: "width100",
                    attrs: {
                      multiple: false,
                      "tree-data": _vm.orgTreeData,
                      placeholder: "请选择企业组织架构",
                    },
                    model: {
                      value: _vm.queryModel.groupIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryModel, "groupIds", $$v)
                      },
                      expression: "queryModel.groupIds",
                    },
                  }),
                  _c("a-input-search", {
                    staticClass: "page-table-search__input width100",
                    attrs: { placeholder: "请输入名称", "allow-clear": "" },
                    model: {
                      value: _vm.queryModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryModel, "name", $$v)
                      },
                      expression: "queryModel.name",
                    },
                  }),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                  },
                },
                on: { change: _vm.tableChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }