


























































import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { UserEntityModel, UserQueryModel } from '@common-src/entity-model/user-entity';
import OrgService from '@common-src/service/org';
import { OrgEntityModel, OrgQueryModel } from '@common-src/entity-model/org-entity';

@Component
export default class OrgUserAddComponent extends TableComponent<UserEntityModel, OrgQueryModel> {
    dialogVisible: boolean = false;
    orgId: string = '';
    orgProjectIdsSearch: string = '';
    orgTreeData: Array<any> = null;
    orgProjectTreeData: Array<any> = null;

    @Prop({ default: false })
    isProjectAdd: boolean;

    mounted() {
        // OrgService.getSelectOrgTree({ noProject: true }).then(res => this.orgTreeData = res);
        OrgService.getSelectOrgTree({ noProject: false }).then(res => {
            this.orgProjectTreeData = res;
        });
        OrgService.getSelectOrgTree({ noProject: true }).then(res => {
            this.orgTreeData = res;
        });
        this.initTable({
            listFunc: OrgService.getUsers,
            queryModel: new OrgQueryModel(),
            tableColumns: OrgEntityModel.getTableColumns()
        });
    }

    dialogOpen(orgId?) {
        this.orgId = orgId;
        this.dialogVisible = true;
        this.queryModel.excludeOrgId = orgId;
        // this.getList();
    }
    dialogOK() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择添加的部门成员');
            return;
        }
        if (!this.queryModel.excludeOrgId && this.isProjectAdd) {
            this.showMessageWarning('请先选择添加的组织架构');
            return;
        }
        return OrgService.addOrgUsers(this.queryModel.excludeOrgId, this.selectedRowKeys).then((res: any) => {
            this.$emit('dialogOK');
            this.selectedRowKeys = [];
            this.dialogClose();
        });
    }
    dialogClose() {
        this.dialogVisible = false;
        this.queryModel = new OrgQueryModel();
        this.listData = [];
    }
}
