

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { UserPwdModel } from '@common-src/entity-model/user-entity';

@Component
export default class UserPwdComponent extends FormDialogComponent<UserPwdModel> {

}
